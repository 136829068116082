import { SiteAssetsClientAdapter } from 'thunderbolt-site-assets-client'
import { FetchFn, PlatformEnvData, PlatformLogger, ViewerAppsUrls } from '@wix/thunderbolt-symbols'
import { BatchedUpdateFunction, BootstrapData, ViewerAPI } from '../types'
import { createPlatformAPI } from './index'
import moduleLoaderFactory from './loadModules'
import { batchUpdateFactory } from './batchUpdate'
import { platformLoggerCreator } from './platformLoggerFactory'
import importScriptsFactory from './importScriptsFactory'

declare const self: {
	importScripts: (url: string) => void
	onmessage: (msg: MessageEvent) => void
	fetch: FetchFn
	location: Location
	commonConfig: BootstrapData['commonConfig']
}

if (self.location && self.location.protocol === 'blob:') {
	/*  blob protocol is used to overcome CORS issue when creating WebWorker.
		fetch will not apply host protocol to requests starting with '//' when host protocol is blob so it must be fixed
		manually */

	const originalFetch = self.fetch.bind(self)

	self.fetch = (url: string, requestInit?: RequestInit) => {
		if (url.startsWith('//')) {
			url = `https:${url}`
		} else if (url.startsWith('/')) {
			url = `${self.location.origin}${url}`
		}
		return originalFetch(url, requestInit)
	}
}

const { initPlatformOnSite, runPlatformOnPage } = createPlatformAPI()

export function initWorkerOnSite({
	platformEnvData,
	appsUrlData,
	componentSdksUrl,
	scriptsCache = {}
}: {
	platformEnvData: PlatformEnvData
	appsUrlData: ViewerAppsUrls
	componentSdksUrl: string
	scriptsCache?: { [_scriptUrl: string]: () => any }
}) {
	const logger = platformLoggerCreator({ biData: platformEnvData.bi, appsUrlData, url: platformEnvData.location.rawUrl, isSSR: platformEnvData.window.isSSR })
	const moduleLoader = moduleLoaderFactory({ importScripts: importScriptsFactory(logger), scriptsCache })
	initPlatformOnSite({ moduleLoader, componentSdksUrl, logger })
}

export async function runWorkerOnPage({
	bootstrapData,
	viewerAPI,
	scriptsCache = {},
	siteAssetsClientWorkerAdapter
}: {
	bootstrapData: BootstrapData
	viewerAPI: ViewerAPI
	componentSdksUrl: string
	scriptsCache?: { [_scriptUrl: string]: () => any }
	siteAssetsClientWorkerAdapter: (fetchFn: FetchFn, logger: PlatformLogger) => SiteAssetsClientAdapter
}) {
	const { platformEnvData, appsUrlData } = bootstrapData
	const isSSR = platformEnvData.window.isSSR
	const logger: PlatformLogger = platformLoggerCreator({ biData: platformEnvData.bi, appsUrlData, url: bootstrapData.platformEnvData.location.rawUrl, isSSR })
	const importScripts = importScriptsFactory(logger)
	const moduleLoader = moduleLoaderFactory({ importScripts, scriptsCache })
	self.commonConfig = bootstrapData.commonConfig
	const flushes: Array<() => void> = []

	if (bootstrapData.mode.disablePlatformBatchedUpdates === false) {
		const createBatchedUpdate = (updateFunc: BatchedUpdateFunction) => {
			const { batchUpdate, flushUpdates } = batchUpdateFactory(updateFunc, !isSSR /* do not schedule flush in SSR */)
			flushes.push(flushUpdates)
			return batchUpdate
		}
		viewerAPI.updateProps = createBatchedUpdate(viewerAPI.updateProps)
		viewerAPI.updateStyles = createBatchedUpdate(viewerAPI.updateStyles)
	}

	await runPlatformOnPage({
		bootstrapData,
		viewerAPI,
		moduleLoader,
		importScripts,
		siteAssetsClient: siteAssetsClientWorkerAdapter(self.fetch, logger),
		logger
	})

	flushes.forEach((flush) => flush())
}
